<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text"> اضافة دورة </h1>
        <v-form v-model="isFormValid">
          <v-row class="mt-5">
            <!-- name -->
            <v-col md="4" sm="6" cols="12">
              <v-text-field v-model="teacherData.title" :rules="Rules.titleRules" dense label="عنوان الدورة"
                outlined></v-text-field>
            </v-col>
            <!-- birthday -->
            <v-col md="4" sm="6" cols="12">
              <v-menu v-model="menuAccountBirthday" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="teacherData.start_date" :rules="Rules.start_date" dense
                    label="تاريخ البدء" outlined clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="teacherData.start_date" @input="menuAccountBirthday = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <!-- birthday -->
            <v-col md="4" sm="6" cols="12">
              <v-menu v-model="menuAccountBirthda" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="teacherData.end_date" :rules="Rules.end_date" dense
                    label="تاريخ الأنتهاء" outlined clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="teacherData.end_date" @input="menuAccountBirthda = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <!-- address -->
            <v-col md="4" sm="6" cols="12">
              <v-text-field v-model="teacherData.price" dense
                label="سعر الدورة" outlined></v-text-field>
            </v-col>
            <!-- mobile -->
            <v-col md="12" sm="12" cols="12">
              <v-textarea v-model="teacherData.description" dense label="وصف الدورة" outlined></v-textarea>
              </v-col>

          </v-row>
        </v-form>
        <div class="d-flex justify-center mt-2">
          <v-btn :loading="addBtnLoading" :disabled="!isFormValid" color="primary" @click="submitAdd()"> اضافة </v-btn>
          <v-btn class="mr-5" @click="cancelAdd()"> الغاء </v-btn>
        </div>
      </v-card>
    </v-container>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { sha512 } from 'js-sha512'
import Api from '../../../api/api'

export default {
  data() {
    return {
      isFormValid: false,

      classLoading: false,

      menuNewPaymentDate: false,

      Rules: {
        titleRules: [value => !!value || 'العنوان مطلوب'],
        account_emailRules: [
          value => !!value || 'الايميل مطلوب',

          // (value) =>
          //   (value && value.length > 4) || "خمسة احرف او ارفام على الاقل",
          v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'يجب ادخال عنوان بريد الكتروني صالح',
        ],
        start_date: [
          value => !!value || 'تاريخ البدء مطلوب',
        ],
        end_date: [
          value => !!value || 'تاريخ الأنتهاء مطلوب',
        ],
        account_birthdayRules: [value => !!value || 'يوم الميلاد مطلوب'],
        account_genderRules: [value => !!value || 'الجنس مطلوب'],
        account_addressRules: [value => !!value || 'العنوان مطلوب'],
        account_mobile: [
          value => (value && value.length === 11) || 'يجب ان يكون 11 رقم',
          value => /^\d*\.?\d*$/.test(value) || 'يجب ان تكون ارقام',
        ],
        salary: [
          value => !!value || 'مبلغ القسط الكلي مطلوب',
          value => (value >= 1000 && value <= 5000000) || value == 0 || 'يجب ان يكون الرقم بين 1000 و 5000000',
          value => /^\d*\.?\d*$/.test(value) || 'يجب ان تكون ارقام',

          // value => /^($|[^0])/.test(value) || 'يجب ان لا يكتب الصفر في البداية',
        ],
        payment: [
          value => !!value || 'مبلغ الواصل مطلوب',
          value => (value >= 1000 && value <= 5000000) || value == 0 || 'يجب ان يكون الرقم بين 1000 و 5000000',
        ],
        withDraw: [
          value => !!value || 'مبلغ الخصم مطلوب',
          value => (value >= 1000 && value <= 5000000) || value == 0 || 'يجب ان يكون الرقم بين 1000 و 5000000',
        ],
        newPaymentDate: [value => !!value || 'تاريخ الدفعة القادمة مطلوب'],
      },

      account_notificatinItems: [
        { text: 'مفعلة', value: 1 },
        { text: 'متوقفة', value: 0 },
      ],

      account_disableItems: [
        { text: 'مفعل', value: 0 },
        { text: 'متوقف', value: 1 },
      ],

      classSchoolData: [],

      // account_genderItems: ['ذكر', 'انثى'],

      menuAccountStartDate: false,

      menuAccountBirthday: false,
      menuAccountBirthda: false,

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      teacherData: {
        title: null,
        description: null,
        start_date: null,
        end_date: null,
        price: null,
      },

      addBtnLoading: false,

      featuredFingerId: 1,
    }
  },
  async created() {
    const resultsLocalStorage = JSON.parse(localStorage.getItem('results'))
    this.featuredFingerId = resultsLocalStorage.features_finger_id

    await this.getClassSchool()
  },
  methods: {
    async getClassSchool() {
      this.classLoading = true
      const response = await Api.getClassSchool()
      if (response.status === 401) {
        this.classLoading = false
        this.$store.dispatch('submitLogout')
        this.classLoading = false
      } else if (response.status === 500) {
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.classLoading = false
        this.classSchoolData = response.data.results
      }
    },

    async submitAdd() {
      this.addBtnLoading = true
      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.addCourse({
        title: this.teacherData.title,
        description: this.teacherData.description,
        start_date: this.teacherData.start_date,
        end_date: this.teacherData.end_date,
        price: this.teacherData.price,
      })

      if (response.status === 401) {
        this.addBtnLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addBtnLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addBtnLoading = false
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    cancelAdd() {
      window.history.back()
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
  },
}
</script>
